<template>
    <input type="text" class="form-control" placeholder="Enter the date" :required="required">
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            $(this.$el).datepicker('setDate', this.value);
        },
        watch:{
            value(value){
                $(this.$el).datepicker('setDate', value);
            }
        }
    }
</script>
