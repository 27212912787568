require('./bootstrap');
require('./modernizr');
require('./scrollbar');
require('./datepicker');
require('./pages');
require('./scripts');

window.Vue = require('vue').default;
window.moment = require('moment');

Vue.component('date-picker', require('./components/DatePicker.vue').default);